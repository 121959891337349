<template>
    <div class="flex flex-col md:flex-row py-10 sm:p-16 md:px-48 md:py-4 lg:px-44 xl:px-72 t-0 l-0 w-screen h-auto flex z-10 sm:justify-center md:justify-between bg-black">
        <div class="flex-col justify-center text-center mb-8 md:mb-0">
            <img class="w-40 h-44 mx-auto" src="../assets/logo.webp" alt="">
        </div>
        <div class="flex flex-col justify-center mx-auto md:mx-0 text-white">
            <h1>Find us on social media!</h1>
            <template v-for="(button, index) in navButtons" :key="index">
                <NavElement :name=" '• ' + button.name" :route="button.route"/>
            </template>
        </div>
    </div>
</template>

<script>
import NavElement from './NavElement.vue';

export default {
  name: 'Footer',
  components: {
      NavElement,
  },

  data() {
    return {
        navButtons: [
            // { name: 'Home', route: '/#home'},
            // { name: 'Location', route: '/#map'},
            // { name: 'Ministries', route: '/ministries'},
            { name: 'Facebook', route: 'https://www.facebook.com/VBTemple/'},
            { name: 'YouTube', route: 'https://www.youtube.com/channel/UCuk-xyQADoe-pvksS3G1HXg'},
        ]
    }
  }
}

</script>

<style scoped>
    .bm-burger-button {
        position: relative;
        width: 36px;
        height: 30px;
        right: 36px;
        top: 36px;
        float: right;
    }
</style>
