<template>
  <div class="relative pt-44 bg-black">
    <div class="relative grid grid-cols-1 px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 z-10">
      <h1 class="text-white text-2xl md:text-4xl font-bold mr-10">Bus Ministry</h1>
      <h1 class="text-white text-lg md:text-xl lg:text-2xl mt-12 md:mb-12">From our inception, Victory has been involved in the bus ministry. In fact, we did not get into the bus ministry because it is convenient—it is expedient—expedient that boys and girls, men and women get to church to hear about Jesus! Boys and girls have been the heart of our ministry since our beginning.</h1>
    </div>

    <div class="relative px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 pb-12 z-10 mt-6 md:mt-0">
      <h1 class="text-white text-lg md:text-xl lg:text-2xl md:mt-12 mb-12">In Mark 10:14, Jesus was displeased, seeing His disciples rebuke folks for bringing children to Him. He told them, “Suffer the little children to come unto Me, and forbid them not…” It is through the bus ministry that we bring folks to Jesus, who loves them and wants to see them receive Him as their personal Saviour.</h1>
      <div class="relative bottom-0"></div>
    </div>
    <div class="absolute top-0 z-0 h-full w-full steeple bg-black opacity-50"></div>
  </div>
</template>

<script>

export default {
  name: 'BusSection',
}
</script>

<style scoped>
.steeple {
    background-image: url("../assets/bus-kids.webp");
    background-repeat: no-repeat !important;
    background-size: 1800px;
  }
</style>