<template>
    <div class="flex mr-8">
        <a :href="$props.route">{{$props.name}}</a>
    </div>
</template>

<script>

export default {
  name: 'NavElement',

  props: {
      name: String,
      route: String
  },
}

</script>
