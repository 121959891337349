<template>
  <div id="home" class="relative pt-44 bg-black">
    <div class="relative grid grid-cols-1 px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 pb-24 z-10">
      <h1 class="text-white text-2xl md:text-4xl font-bold mr-10">Standing Strong</h1>
      <h1 class="text-white text-lg md:text-xl lg:text-2xl mt-12 md:mb-12">At the foot of Clark’s Mountain stands Victory Baptist Temple. In 1977, God used a man to plant an independent Baptist, Bible-believing, local church in this modest community. Elmo Parker envisioned a “book of Acts” church where folks could hear the gospel of Jesus Christ so that they could be saved. Today, that same vision continues under the leadership of Pastor Brian Wilson.</h1>
      <!-- <a href="#contact" class="invisible sm:visible text-white rounded-md bg-red-800 py-2 px-3">Let's get started ></a> -->
    </div>

    <div class="relative px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 pb-24 z-10">
      <h1 class="text-white text-lg md:text-xl lg:text-2xl md:mt-12 mb-12">In changing times, it is important to attend a church whose purpose has not changed. Visitors often comment after enjoying one of our services, “It’s like stepping back in time.” We consider that a compliment. We want to be “steadfast, unmovable, always abounding in the work of the Lord,” not changing with the times. That means we sing hymns from a songbook, provide Sunday school classes for all ages, have an outstanding choir along with special music, and hear Bible-based preaching from the King James Bible. In summary, we simply want to follow Christ, who is the same “yesterday, today, and forever” and continue to stand strong.</h1>
      <div class="relative bottom-0"></div>
    </div>
    <div class="absolute top-0 z-0 h-full w-full steeple bg-black opacity-50"></div>

    <div class="absolute bottom-24"></div>
  </div>
</template>

<script>

export default {
  name: 'MainSection',
}
</script>

<style scoped>
.steeple {
    background-image: url("../assets/victory.webp");
    /* background-attachment: fixed; */
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 3000px;
  }
</style>