<template>
  <div class="relative pt-44 bg-black">
    <div class="relative grid grid-cols-1 px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 z-10">
      <h1 class="text-white text-2xl md:text-4xl font-bold mr-10">Academy</h1>
      <h1 class="text-white text-lg md:text-xl lg:text-2xl mt-12 md:mb-12">Realizing that it is the Christian home to which God has given the authority and ability to train up its young people, Victory Baptist Academy was founded in 1982 to assist parents in the training process. We believe a biblical viewpoint is necessary in every area of life: spiritual growth, personal self-discipline, traditional education, and patriotism. These areas must be strongly stressed and taught during the formative years of a child’s training. We use the Abeka curriculum from kindergarten through high school and take advantage of the Abeka online courses for junior high and senior high students.</h1>
    </div>

    <div class="relative px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 pb-12 z-10 mt-6 md:mt-0">
      <h1 class="text-white text-lg md:text-xl lg:text-2xl md:mt-12 mb-12">Interested? Call or email us for an application.</h1>
      <div class="relative bottom-0"></div>
    </div>
    <div class="absolute top-0 z-0 h-full w-full steeple bg-black opacity-30"></div>
  </div>
  <!-- <div class="relative pt-10">
    <div class="relative grid grid-cols-1 px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 z-10 mt-6">
      <h1 class="text-black text-lg md:text-xl lg:text-2xl mt-12 md:mb-12">The purpose of VBA is to give the Christian parent an opportunity to obey the commands of Scripture to “Train up a child in the way he should go….” and… “Bring them up in the nurture and admonition of the Lord” (Proverbs 22:6; Ephesians 6:4). We exist as an extension of the Christian home in our society so the Christian parent will not violate the Scripture which says, “Cease my son to heed the instruction that causeth to err from the words of knowledge” (Proverbs 19:27) and, “Learn not the ways of the heathen…” (Jeremiah 10:2). There is no way for the Christians, who are responsible for his children’s education, to obey the Scriptures apart from a Christian education. It is for this reason VBA was established.</h1>
    </div>

    <div class="relative px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 pb-4 z-10 mt-6 md:mt-0">
      <h1 class="text-black text-lg md:text-xl lg:text-2xl md:mt-12 mb-12">Our Christian school has the responsibility to provide the best possible education. A Biblical viewpoint in the vital areas of life-spiritual growth, education, personal self-discipline, and patriotism-must be strongly stressed to each student during his years of training. We desire to minister to the needs of the whole child and to promote his spiritual and moral growth, academic and intellectual progress, and physical and social development.</h1>
      <div class="relative bottom-0"></div>
    </div>
  </div> -->
</template>

<script>

export default {
  name: 'AcademySection',
}
</script>

<style scoped>
.steeple {
    background-image: url("../assets/classroom.webp");
    background-repeat: no-repeat !important;
    background-size: 1800px;
  }
</style>