<template>
  <div id="contact" class="flex flex-col md:flex-row pt-10 pb-10 md:pb-0 px-10">
    <div class="flex-col md:flex w-full justify-between mb-12 text-center">
      <!-- <p class="text-xl text-gray-600">Our service times:</p> -->
      <div class="grid grid-rows-4 gap-4">
        <h1 class="font-bold text-black">Sunday School - 10:00 a.m.</h1>
        <h1 class="font-bold text-black">Sunday Morning Service - 11:00 a.m.</h1>
        <h1 class="font-bold text-black">Sunday Evening Service - 6:00 p.m.</h1>
        <h1 class="font-bold text-black">Midweek Service - Wed. 7:00 p.m.</h1>
      </div>
    </div>
    <div class="flex-col md:flex w-full text-center">
      <p class="text-xl text-gray-600">Our contact info:</p>
      <div class="grid grid-rows-3 gap-4">
        <p class="font-bold text-black">402 Wayne 341, Piedmont, MO 63957</p>
        <a href="mailto:victorybaptisttemple@gmail.com" class="font-bold text-black">victorybaptisttemple@gmail.com</a>
        <a href="tel:573-223-4198" class="font-bold text-black">573-223-4198 Phone</a>
        <a href="#" class="font-bold text-black">573-223-2787 Fax</a>
      </div>
    </div>
  </div>
</template>

<script>
// import CheckMark from './icons/CheckMark.vue';

export default {
  name: 'Contact',
  components: {
    // CheckMark,
  },
}
</script>
