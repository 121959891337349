<template>
  <div>
    <Navbar />
    <BusSection />
    <CollegeSection />
    <AcademySection />
    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import BusSection from '../components/BusSection.vue';
import CollegeSection from '../components/CollegeSection.vue';
import AcademySection from '../components/AcademySection.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Ministries',
  components: {
    Navbar,
    BusSection,
    CollegeSection,
    AcademySection,
    Footer,
  },
}
</script>