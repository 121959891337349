<template>
  <div>
    <Navbar/>
    <div class="w-full h-screen pt-24">
      <iframe class="w-full h-full" src="https://calendar.google.com/calendar/embed?&wkst=1&bgcolor=%23ffffff&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=0&showCalendars=0&showTz=1&src=victorybaptisttemple%40gmail.com&ctz=America%2FChicago&color=%230B8043" style="border:solid 1px #777" frameborder="0" scrolling="no"></iframe>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
  },
}
</script>