<template>
  <router-view/>
</template>

<script>
// import Home from './components/Home.vue'

export default {
  name: 'App',
  // components: {
  //   Home
  // }
}
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
</style>
