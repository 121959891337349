<template>
  <div class="relative pt-40 z-10">
    <div class="relative grid grid-cols-1 px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 z-10">
      <h1 class="text-black text-2xl md:text-4xl font-bold mr-10">Berean Baptist College</h1>
      <h1 class="text-black text-lg md:text-xl lg:text-2xl mt-12 md:mb-12">Whether in the classroom or via distance learning, our goal is to faithfully train men and women in the Word of God to serve the Lord Jesus Christ.</h1>
      <!-- <a href="#contact" class="invisible sm:visible text-white rounded-md bg-red-800 py-2 px-3">Let's get started ></a> -->
    </div>

    <div class="relative px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 z-10 mt-6 md:mt-0">
      <h1 class="text-black text-lg md:text-xl lg:text-2xl md:mt-12 mb-12">II Timothy 2:2 “And the things that thou hast heard of me among many witnesses, the same commit thou to faithful men, who shall be able to teach others also.”</h1>
      <div class="relative bottom-0"></div>
    </div>

    <div class="relative px-10 md:px-0 md:left-24 lg:left-36 w-full md:w-3/4 pb-12 z-10 mt-6 md:mt-0">
      <h1 class="text-black text-lg md:text-xl lg:text-2xl mb-12">Contact Dr. Andy Tully with questions at (573) 429-2820.</h1>
      <div class="relative bottom-0"></div>
    </div>
    <div class="absolute top-0 z-0 h-full w-full steeple bg-black opacity-30"></div>
  </div>
</template>

<script>

export default {
  name: 'CollegeSection',
}
</script>

<style scoped>
.steeple {
    background-image: url("../assets/bible.webp");
    background-repeat: no-repeat !important;
    background-size: 1800px;
    background-position: center;
  }
</style>