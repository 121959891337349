<template>
    <div class="fixed md:pl-8 lg:pr-16 xl:pr-16 lg:pl-32 xl:pr-36 t-0 l-0 w-screen h-24 md:h-20 pt-4 flex z-10 justify-between bg-white z-20 border-black border-b-2">
        <div class="hidden md:flex md:flex-row">
            <a href="/" class="text-black font-bold text-2xl mt-2">Victory Baptist Temple</a>
        </div>
        <div class="hidden md:flex md:flex-row pt-3">
            <template v-for="(button, index) in navButtons" :key="index">
                <NavElement :name="button.name" :route="button.route"/>
            </template>
        </div>
        <div class="flex md:hidden">
            <Slide right>
                <template v-for="(button, index) in navButtons" :key="index">
                    <a :id="button.name" :href="button.route">
                        <span>{{button.name}}</span>
                    </a>
                </template>
            </Slide>
        </div>
    </div>
</template>

<script>
import NavElement from './NavElement.vue';
import { Slide } from 'vue3-burger-menu';

export default {
  name: 'Navbar',
  components: {
      NavElement,
      Slide
  },

  data() {
    return {
        navButtons: [
            { name: 'Home', route: '/#home'},
            { name: 'Location', route: '/location'},
            { name: 'Calendar', route: '/calendar'},
            { name: 'Ministries', route: '/ministries'},
            { name: 'Contact Us', route: '/#contact'},
        ]
    }
  }
}

</script>

<style scoped>
    .bm-burger-button {
        position: relative;
        width: 36px;
        height: 30px;
        right: 36px;
        top: 36px;
        float: right;
    }
</style>
