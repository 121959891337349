<template>
  <div class="pt-20">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-0 h-2/3 border-t-0 border-black border-black border-l-2 border-b-2 border-r-2">
      <iframe class="w-full h-96 lg:h-full" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1589.5534020983437!2d-90.6818593!3d37.1739327!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d77a386d7eb149%3A0x9964dab946be3c94!2sVictory%20Baptist%20Temple!5e0!3m2!1sen!2sus!4v1663108428069!5m2!1sen!2sus" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div class="relative w-full h-full border-black md:border-l-2 border-t-2 md:border-t-0 bg-black pt-4 sm:pt-8 md:pt-24">
        <div class="m-4 md:m-6">
          <h1 class="relative text-4xl font-bold text-center z-10 text-white">Come Visit One of Our Services!</h1>
          <h1 class="relative mt-4 h-60 sm:h-40 md:h-60 text-left md:text-center lg:mr-8 text-lg text-center md:text-left z-10 text-white text-xl font-bold">Your first visit at Victory will be refreshing, friendly and enjoyable. From the very start you’ll experience inspiring music, biblical preaching, and friendly folks. You may find Victory Baptist a church you’ll want to call your home church!</h1>
        </div>
        <div class="absolute top-0 z-0 h-full w-full steeple bg-black opacity-50 bg-black"></div>
      </div>
    </div>
    <div class="relative border-l-2 border-black border-black border-r-2 border-b-2 h-64 bg-black">
      <h1 class="absolute mx-4 md:mx-32 mt-12 sm:mt-20 md:mt-16 lg:mt-20 z-10 w-auto text-center text-white text-lg md:text-2xl font-bold">On Vacation? Clearwater Lake is a popular destination for camping during the summer months. If you’re at the lake or at Sam A. Baker State Park, come enjoy any of our services.</h1>
      <img class="absolute object-cover w-full h-full md:h-full opacity-50" src="../assets/lake.webp"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Map',
  data() {
    return {
      staff: false
    }
  },
  methods: {
    setStaff(value) {
      this.staff = value;
    }
  }
}
</script>

<style scoped>
.steeple {
    background-image: url("../assets/songbook.webp");
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: 900px;
  }
</style>
