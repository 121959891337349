import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Calendar from '../views/Calendar.vue';
import Ministries from '../views/Ministries.vue';
import Location from '../views/Location.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/ministries',
    name: 'Ministries',
    component: Ministries
  },
  {
    path: '/location',
    name: 'Location',
    component: Location
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
