<template>
  <div>
    <Navbar />
    <Map />
    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';
import Map from '../components/Map.vue';

export default {
  name: 'Ministries',
  components: {
    Navbar,
    Map,
    Footer,
  },
}
</script>