<template>
  <div>
    <Navbar/>
    <MainSection />
    <!-- <Map /> -->
    <Contact />
    <!-- <Services /> -->
    <!-- <Banner /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import MainSection from '../components/MainSection.vue';
// import Map from '../components/Map.vue';
import Contact from '../components/Contact.vue';
import Footer from '../components/Footer.vue';
// import Services from './Services.vue';
// import Banner from './Banner.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    MainSection,
    // Map,
    Contact,
    Footer,
    // Services,
    // Banner,
  },
}
</script>